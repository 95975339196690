import { Customer, CustomerGroup } from '@medusajs/medusa';
import { Table } from 'antd';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { getDynamic365CustomerId } from '../../helpers/customer.helper';
import { useDealer } from '../../hooks/use-dealer';
import BasePagination from '../pagination/BasePagination';
import Card, { CardBody, CardHeader } from '../ui/Card';

const CUSTOMER_TAB = 'customer';
const GROUP_TAB = 'group';

const DealerMemberTable = () => {
  const [activeTab, setActiveTab] = useState(CUSTOMER_TAB);

  const renderTable = () => {
    switch (activeTab) {
      case CUSTOMER_TAB:
        return <CustomerTable />;
      case GROUP_TAB:
        return <CustomerGroupTable />;
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardHeader>
        <ul className="m-0 flex gap-4">
          <li
            onClick={() => setActiveTab(CUSTOMER_TAB)}
            className={twMerge(
              'cursor-pointer font-semibold text-light-title',
              activeTab === CUSTOMER_TAB && 'pointer-events-none text-primary-6'
            )}
          >
            ลูกค้า
          </li>
          <li
            onClick={() => setActiveTab(GROUP_TAB)}
            className={twMerge(
              'cursor-pointer font-semibold text-light-title',
              activeTab === GROUP_TAB && 'pointer-events-none text-primary-6'
            )}
          >
            กลุ่มบริษัท
          </li>
        </ul>
      </CardHeader>
      <CardBody className="bg-white">{renderTable()}</CardBody>
    </Card>
  );
};

const CustomerTable = () => {
  const { customers, customerPage, setCustomerPage, isCustomerLoading } =
    useDealer();

  return (
    <>
      <Table
        pagination={false}
        scroll={{ x: true }}
        loading={isCustomerLoading}
        rowKey={(record) => record.id}
        dataSource={customers.customers}
        columns={[
          {
            title: 'ชื่อ',
            render: (value) => (
              <div>{`${value?.first_name} ${value?.last_name}`}</div>
            ),
          },
          {
            title: 'อีเมล',
            dataIndex: 'email',
          },
          {
            title: 'กลุ่มบริษัท',
            render: (value: Customer) =>
              value.groups.map((group) => group.name).join(', ') || '-',
          },
          {
            title: 'Dynamic 365 ID',
            render: (value: Customer) => getDynamic365CustomerId(value),
          },
        ]}
      />
      <BasePagination
        total={customers.total}
        pageSize={customers.pageSize}
        current={customerPage}
        onChange={setCustomerPage}
      />
    </>
  );
};

const CustomerGroupTable = () => {
  const {
    customerGroups,
    customerGroupPage,
    setCustomerGroupPage,
    isCustomerGroupLoading,
  } = useDealer();

  return (
    <>
      <Table
        pagination={false}
        scroll={{ x: true }}
        loading={isCustomerGroupLoading}
        rowKey={(record) => record.id}
        dataSource={customerGroups.customerGroups}
        columns={[
          {
            title: 'บริษัท',
            dataIndex: 'name',
          },

          {
            title: 'Dynamic 365 ID',
            render: (value: CustomerGroup) =>
              value.metadata.dynamic_365_customer_id || '-',
          },
        ]}
      />
      <BasePagination
        total={customerGroups.total}
        pageSize={customerGroups.pageSize}
        current={customerGroupPage}
        onChange={setCustomerGroupPage}
      />
    </>
  );
};

export default DealerMemberTable;
