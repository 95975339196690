import { Avatar } from 'antd';
import React from 'react';

import { useDealer } from '../../hooks/use-dealer';
import { Dealer } from '../../types/entities';
import Card, { CardBody } from '../ui/Card';

const DealerDetail = ({ dealer }: { dealer: Dealer }) => {
  return (
    <Card>
      <CardBody className="bg-white">
        <div className="mb-4 text-xl font-semibold text-light-title">
          {dealer.name}
        </div>
        <div className="mb-1.5 font-medium text-light-secondary">ดีลเลอร์</div>
        <div className="flex gap-x-5">
          {dealer.dealerStaffs.map((staff) => (
            <div key={staff.customer.id} className="flex items-center">
              <Avatar size={24}>
                <span className="!text-sm font-medium">
                  {staff?.customer.first_name[0].toUpperCase()}
                </span>
              </Avatar>
              <span className="ml-2 text-sm text-light-title">
                {staff.customer.first_name}
              </span>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default DealerDetail;
