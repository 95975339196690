import React from 'react';

import { useDealer } from '../../hooks/use-dealer';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import MainLayout from '../layout/MainLayout';
import AccountLayout from './AccountLayout';
import DealerDetail from './DealerDetail';
import DealerMemberTable from './DealerMemberTable';

const DealerPage = () => {
  const { dealer } = useDealer();

  if (!dealer) return null;

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'บัญชีของฉัน',
            link: '/account',
          },
          {
            title: 'สมาชิกดีลเลอร์',
            link: '/account/dealer',
          },
        ]}
        title={dealer.name}
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="mb-3">
            <DealerDetail dealer={dealer} />
          </div>
          <DealerMemberTable />
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default DealerPage;
