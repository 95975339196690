import { Customer, CustomerGroup } from '@medusajs/medusa';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';

import { getManagedDealers } from '../helpers/customer.helper';
import { QUERY_KEYS } from '../services/queryKeys';
import { Dealer } from '../types/entities';
import { useCustomer } from './use-customer';

interface CustomerResponse {
  customers: Customer[];
  count: number;
  offset: number;
  limit: number;
}

interface CustomerGroupResponse {
  groups: CustomerGroup[];
  count: number;
  offset: number;
  limit: number;
}

const PAGE_SIZE = 24;

export const useDealer = () => {
  const { customer } = useCustomer();

  const [managedDealer] = getManagedDealers(customer!);

  const { data: dealerResponse } = useQuery({
    queryKey: [QUERY_KEYS.dealer.get, managedDealer?.id],
    queryFn: () => axios.get<Dealer>(`/store/dealers/${managedDealer?.id}`),
    enabled: !!managedDealer?.id,
  });

  const [customerPage, setCustomerPage] = useState(1);
  const { data: customerResponse, isLoading: isCustomerLoading } = useQuery({
    queryKey: [QUERY_KEYS.dealerCustomer.list, customerPage],
    queryFn: () =>
      axios.get<CustomerResponse>(
        `/store/dealers/${managedDealer?.id}/customers`,
        {
          params: {
            offset: (customerPage - 1) * PAGE_SIZE,
            limit: PAGE_SIZE,
          },
        }
      ),
    enabled: !!managedDealer?.id,
  });

  const [customerGroupPage, setCustomerGroupPage] = useState(1);
  const { data: customerGroupResponse, isLoading: isCustomerGroupLoading } =
    useQuery({
      queryKey: [QUERY_KEYS.dealerCustomerGroup.list, customerGroupPage],
      queryFn: () =>
        axios.get<CustomerGroupResponse>(
          `/store/dealers/${managedDealer?.id}/customer-groups`,
          {
            params: {
              offset: (customerGroupPage - 1) * PAGE_SIZE,
              limit: PAGE_SIZE,
            },
          }
        ),
      enabled: !!managedDealer?.id,
    });

  return {
    dealer: dealerResponse?.data,
    customers: {
      customers: customerResponse?.data.customers || [],
      total: customerResponse?.data.count || 0,
      page: customerPage || 1,
      pageSize: PAGE_SIZE,
    },
    isCustomerLoading,
    customerPage,
    setCustomerPage,
    customerGroups: {
      customerGroups: customerGroupResponse?.data.groups || [],
      total: customerGroupResponse?.data.count || 0,
      page: customerPage || 1,
      pageSize: PAGE_SIZE,
    },
    isCustomerGroupLoading,
    customerGroupPage,
    setCustomerGroupPage,
  };
};
